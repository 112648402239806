
import { splice } from 'postcss-rtl/lib/affected-props';
<template>
    <section id="dashboard-ecommerce" class="font-14">
      <div class="sec1 d-flex justify-content-between pb-1">
        <h3 class="text-black d-flex">
          Challenge{{ app }}
        </h3>
      </div>
      
      <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
      </div>
      <div v-else class="drop-zone">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el p-3">
  
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="addBannerHandelClick">
              <b-row>
                
                <b-col md="2" class="pl-2">
                <b-form-group class="mb-2" label="Heading">
                  <b-form-checkbox v-model="heading" :checked="status" class="my-checkbox ml-1 mt-1" name="check-button" switch>
                    <span class="switch-icon-left">
                      Active
                    </span>
                    <span class="switch-icon-right">
                      Inactive
                    </span>
                  </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="2" class="pl-2">
                <b-form-group class="mb-2" label="Challenge">
                  <b-form-checkbox v-model="challenge" :checked="status" class="my-checkbox ml-1 mt-1" name="check-button" switch>
                    <span class="switch-icon-left">
                      Active
                    </span>
                    <span class="switch-icon-right">
                      Inactive
                    </span>
                  </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="2" class="pl-2">
                <b-form-group class="mb-2" label="Prizes">
                  <b-form-checkbox v-model="prizes" :checked="status" class="my-checkbox ml-1 mt-1" name="check-button" switch>
                    <span class="switch-icon-left">
                      Active
                    </span>
                    <span class="switch-icon-right">
                      Inactive
                    </span>
                  </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="2" class="pl-2">
                <b-form-group class="mb-2" label="Rules">
                  <b-form-checkbox v-model="rules" :checked="status" class="my-checkbox ml-1 mt-1" name="check-button" switch>
                    <span class="switch-icon-left">
                      Active
                    </span>
                    <span class="switch-icon-right">
                      Inactive
                    </span>
                  </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="2" class="pl-2">
                <b-form-group class="mb-2" label="Terms">
                  <b-form-checkbox v-model="terms" :checked="status" class="my-checkbox ml-1 mt-1" name="check-button" switch>
                    <span class="switch-icon-left">
                      Active
                    </span>
                    <span class="switch-icon-right">
                      Inactive
                    </span>
                  </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Heading (English)">
                        <quill-editor v-model="heading_en"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Heading (Arabic)">
                        <quill-editor v-model="heading_ar"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Challenge (English)">
                        <quill-editor v-model="challenge_en"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Challenge (Arabic)">
                        <quill-editor v-model="challenge_ar"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Prizes (English)">
                        <quill-editor v-model="prizes_en"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Prizes (Arabic)">
                        <quill-editor v-model="prizes_ar"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Rules (English)">
                        <quill-editor v-model="rules_en"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Rules (Arabic)">
                        <quill-editor v-model="rules_ar"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Terms (English)">
                        <quill-editor v-model="terms_en"/>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group class="mb-2" label="Terms (Arabic)">
                        <quill-editor v-model="terms_ar"/>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="pl-2">
                  <b-form-group class="mb-2" label="Video Link (English)">
                    <validation-provider #default="{ errors }" name="Video Link (English)" rules="required">
                      <b-form-input v-model="video_link_en" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Video Link (English)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="pl-2">
                  <b-form-group class="mb-2" label="Video Link (Arabic)">
                    <validation-provider #default="{ errors }" name="Video Link (Arabic)" rules="required">
                      <b-form-input v-model="video_link_ar" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Video Link (Arabic)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="pl-2">
                    <button type="button" class="btn btn-primary float-right"  style="border-radius: 16px; padding: 16px"
                    @click="AddMore()" >
                    +&nbsp; Add More
                    </button>
                </b-col>
                <b-col md="12" class="pl-2">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Image(English)</th>
                            <th>Image(Arabic)</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in imageList" :key="index">
                            <td>
                                <validation-provider #default="{ errors }" name="Image(English)" rules="required">
                                <b-form-input v-model="item.image" :state="errors.length > 0 ? false : null"
                                    placeholder="Enter Image(English)" />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </td>
                            <td>
                                <validation-provider #default="{ errors }" name="Image(Arabic)" rules="required">
                                <b-form-input v-model="item.image_ar" :state="errors.length > 0 ? false : null"
                                    placeholder="Enter Image(Arabic)" />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </td>
                            <td>
                                <button type="button" class="btn notofication-btn" @click="DeleteImage(index)"
                                style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
                                <img src="../../../assets/images/client/delete.png" alt="Snow" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </b-col>
                <b-col md="12" class="pl-2">
                  <button type="button" style="float:right" @click="addBannerHandelClick" class="btn btn-primary">Submit</button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </section>
  </template>
  
  <script>
  import draggable from "vuedraggable";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, integer } from "@validations";
  import VSelect from "vue-select";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
  import { mapState } from 'vuex';
  export default {
    components: {
      draggable,
      ValidationProvider,
      ValidationObserver,
      VSelect,
      quillEditor
    },
    data() {
      return {
        data: {},
        status:false,
        client: {},
        heading:false,
        challenge:false,
        prizes:false,
        rules:false,
        terms:false,
        heading_en:'',
        heading_ar:'',
        challenge_en:'',
        challenge_ar:'',
        prizes_en:'',
        prizes_ar:'',
        rules_en:'',
        rules_ar:'',
        terms_en:'',
        terms_ar:'',
        video_link_en:'',
        video_link_ar:'',
        imageList:[
            {
                image_en:'',
                image_ar:''
            }
        ],
        coupon: {},
        customUrl: '',
        baseURL: process.env.VUE_APP_BASE_URL,
        host: window.location.origin,
        categoriesData: [
          { name: "Banner.jpg" },
          { name: "Banner1.jpg" },
          { name: "Banner2.jpg" },
        ],
        popoverShow: false,
        popoverShow1: false,
        value: "1",
        edit: false,
        isLoading:false,
      };
    },
     watch: {
      'sidebar': {
        handler: 'checkIsValid',
        immediate: true, 
      },
    '$store.state.application': {
      handler: 'checkIsValid',
      immediate: true,
    },
    },
    mounted() {
        this.getEditItem(this.editItemId)
    //   this.getCategories()
    },
    computed: {
        ...mapState(['sidebar']),
    },
    methods: {
      checkIsValid() {
        if (this.sidebar) {
          let is_valid = this.sidebar.some(data => data.name == 'Challenge');
          if (!is_valid) {
            this.$router.push('/');
          }
        }
      },
        AddMore(){
            this.imageList.push(
            {
                image_en:'',
                image_ar:''
            })
        },
        DeleteImage(id){
            this.imageList.splice(id,1);
        },
      getEditItem() {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/challenge/index`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            this.editItem = json.data.data
            this.imageList = json.data.imageList
            this.heading = this.editItem.heading ? true : false
            this.challenge = this.editItem.challenge ? true : false
            this.prizes = this.editItem.prizes ? true : false
            this.rules = this.editItem.rules ? true : false
            this.terms = this.editItem.terms ? true : false
            this.heading_en = this.editItem.heading_en
            this.heading_ar = this.editItem.heading_ar
            this.challenge_en = this.editItem.challenge_en
            this.challenge_ar = this.editItem.challenge_ar
            this.prizes_en = this.editItem.prizes_en
            this.prizes_ar = this.editItem.prizes_ar
            this.rules_en = this.editItem.rules_en
            this.rules_ar = this.editItem.rules_ar
            this.terms_en = this.editItem.terms_en
            this.terms_ar = this.editItem.terms_ar
            this.video_link_en = this.editItem.video_link_en
            this.video_link_ar = this.editItem.video_link_ar
            this.edit = true
        this.isLoading = false
            this.$forceUpdate();
          })
      },
      addBannerHandelClick() {
        this.$refs.submitPrevent.validate().then((success) => {              
           if(!success){
          this.$swal({
                  title: "Warning!!",
                  text: `Please Select Require Fields`,
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-warning",
                  },
                  buttonsStyling: false,
                });
          }else{
              let image_en = []
            let image_ar = []
            this.imageList.map(i => {
                image_en.push(i.image)                              
                image_ar.push(i.image_ar)                              
            })
            let data = {
            heading : this.heading ? 1 : 0,
            challenge : this.challenge ? 1 : 0,
            prizes : this.prizes ? 1 : 0,
            rules : this.rules ? 1 : 0,
            terms : this.terms ? 1 : 0,
            heading_en : this.heading_en,
            heading_ar : this.heading_ar,
            challenge_en : this.challenge_en,
            challenge_ar : this.challenge_ar,
            prizes_en : this.prizes_en,
            prizes_ar : this.prizes_ar,
            rules_en : this.rules_en,
            rules_ar : this.rules_ar,
            terms_en : this.terms_en,
            terms_ar : this.terms_ar,
            video_link_en : this.video_link_en,
            video_link_ar : this.video_link_ar,
            images_en: image_en,
            images_ar: image_ar,
            }  
                   this.isLoading = true
              this.$http.post(`${this.baseURL}/challenge/create`, data,
                {
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  withCredentials: true
                }).then((json) => {
                  console.log(json)
                  this.isLoading = false
                  if (json.data.status == 201) {
                    this.$swal({
                      title: "Submitted",
                      text: json.data.message
                        ? `${json.data.message}`
                        : json.data.success
                          ? `${json.data.success}`
                          : "Submitted SuccessFully",
                      icon: "success",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    })
                    this.getEditItem();
                  } else {
                    let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => { return `${item}:${json.data.data[item]}` }) : json.data.message
                      ? `${json.data.message}`
                      : "Error"
                    this.$swal({
                      title: "Error!!",
                      text: text,
                      icon: "error",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  }
                })
              }
            })
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/dashboard-ecommerce.scss";
  @import "@core/scss/vue/libs/chart-apex.scss";
  // .draggable-task-handle {
  //     transform: translateY(-50%);
  //     visibility:visible;
  //     cursor: move;
  
  //     .todo-task-list .todo-item:hover & {
  //       visibility: hidden;
  //     }
  // }
  
  .status-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #0ac947;
    border: 1px solid #0ac947;
    border-radius: 4px;
  }
  
  .mb-half {
    margin-bottom: 5px;
  }
  
  .notofication-btn {
    background-color: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    padding: 12px 12px;
  }
  
  .slide-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    padding: 15px;
    margin: 0;
    width: 200px;
    float: right;
  }
  
  .see-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    width: 125px;
  }
  
  .bs-popover-left {
    border-radius: 14px !important;
    border: transparent;
    min-width: 140px !important;
    max-width: 350px !important;
  }
  
  .bs-popover-left .popover-body {
    display: flex;
    justify-content: space-between;
    border: none;
  }
  
  .bs-popover-bottom {
    background-color: #ffffff;
    // height: 190px;
    border: transparent;
    border: none;
    border-radius: 14px;
  }
  
  .popover-body {
    border: none !important;
  }
  
  .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #ffffff;
  }
  
  .vs-select__options__content {
    background-color: #ffffff !important;
  }
  
  .pop-btn {
    padding: 0 !important;
  }
  
  .modal-dialog {
    // 
  }
  
  .modal-body {
     padding: 0px 40px 40px 40px !important
  }
  
  .form-control {
    border-radius: 14px;
  }
  
  .modal-content {
    border-radius: 24px;
  }
  
  .modal-header {
    border-radius: 24px;
    height:50px;
  }
  
  textarea.form-control {
    min-height: 150px !important;
  }
  
  
  .modal-footer {
    display: none;
  }
  
  .modal-footer1 {
    display: flex;
    float: right;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.8rem 1.4rem;
  }
  
  .cate-vs-select {
    max-width: none;
  }</style>
  